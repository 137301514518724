.customInput {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.custom-tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #ffffff;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.custom-tab-label:hover {
  /* background: #1a252f; */
  background: navy;
}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}

.customInput:checked + .custom-tab-label {
  /* background: #1a252f; */
  background: navy;
}
.customInput:checked + .custom-tab-label::after {
  transform: rotate(90deg);
}
.customInput:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}